import React from 'react'
import PropTypes from 'prop-types'
import { Input, InputNumber, Select, Button, DatePicker, Radio, Checkbox, AutoComplete } from 'antd'
import moment from 'moment'
import classNames from 'classnames'

import './index.styl'

const { TextArea } = Input
const { Option } = Select

/**
 * 基础搜索框组件
 */

export const SearchItem = ({ children, label, isRequire, className }) => {
    return (
        <div className={'com-search-item com-flex' + (className ? ' ' + className : '')}>
            {label && (
                <span className="label">
                    {isRequire && <span className="text-red-ff">*</span>}
                    {label}:
                </span>
            )}
            {children}
        </div>
    )
}
SearchItem.propTypes = {
    label: PropTypes.string,
    isRequire: PropTypes.bool,
    className: PropTypes.string
}

/**
 * 基础搜索框Input组件
 */
export const SearchInputItem = ({
    label,
    onChange,
    placeholder,
    value,
    disabled,
    name,
    suffix,
    isRequire,
    maxLength,
    className,
    allowClear,
    id,
    width,
    suffixOut
}) => {
    const handleSearch = (value, key) => {
        onChange && onChange(value, key, id)
    }
    return (
        <SearchItem label={label} isRequire={isRequire} className={className}>
            <Input
                onChange={(e) => handleSearch(e, name)}
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                suffix={suffix}
                maxLength={maxLength}
                allowClear={allowClear}
                width={width}
            />
            {suffixOut}
        </SearchItem>
    )
}
SearchInputItem.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    name: PropTypes.string,
    suffix: PropTypes.node,
    isRequire: PropTypes.bool,
    maxLength: PropTypes.number,
    className: PropTypes.string,
    allowClear: PropTypes.bool,
    id: PropTypes.string,
    width: PropTypes.number,
    suffixOut: PropTypes.node
}

/**
 *数字Input
 */
export const InputNumberItem = ({
    label,
    onChange,
    placeholder,
    value,
    disabled,
    name,
    suffix,
    isRequire,
    maxLength,
    className,
    min,
    max,
    defaultValue,
    id,
    width,
    addonBefore,
    addonAfter,
    prefix
}) => {
    const handleChange = (value, key) => {
        onChange && onChange(value, key, id)
    }
    return (
        <SearchItem label={label} isRequire={isRequire} className={className}>
            <InputNumber
                onChange={(e) => handleChange(e, name)}
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                suffix={suffix}
                maxLength={maxLength}
                width={width}
                min={min}
                max={max}
                defaultValue={defaultValue}
                addonBefore={addonBefore}
                addonAfter={addonAfter}
                prefix={prefix}
            />
        </SearchItem>
    )
}
InputNumberItem.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    name: PropTypes.string,
    suffix: PropTypes.node,
    isRequire: PropTypes.bool,
    maxLength: PropTypes.number,
    className: PropTypes.string,
    id: PropTypes.string,
    width: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    defaultValue: PropTypes.number,
    addonBefore: PropTypes.node,
    addonAfter: PropTypes.node,
    prefix: PropTypes.string
}

export const TextAreaItem = ({
    label,
    onChange,
    placeholder,
    value,
    disabled,
    name,
    isRequire,
    maxLength,
    autoSize,
    className
}) => {
    const handleSearch = (value, key) => {
        onChange && onChange(value, key)
    }
    return (
        <SearchItem label={label} isRequire={isRequire} className={className}>
            <TextArea
                onChange={(e) => handleSearch(e, name)}
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                maxLength={maxLength}
                autoSize={autoSize}
            />
        </SearchItem>
    )
}
TextAreaItem.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    name: PropTypes.string,
    isRequire: PropTypes.bool,
    maxLength: PropTypes.number,
    autoSize: PropTypes.object,
    className: PropTypes.string
}

//flex wrapper
export const SearchContentWrapper = ({ children, className }) => {
    return <div className={'com-search-content' + (className ? ' ' + className : '')}>{children}</div>
}
SearchContentWrapper.propTypes = {
    className: PropTypes.string
}

export const SearchGridWrapper = ({ children, className }) => {
    return <div className={classNames('search-grid-wrapper', { className })}>{children}</div>
}
SearchGridWrapper.propTypes = {
    className: PropTypes.string
}

/**
 * 基础Select组件-支持模糊搜索
 */

export const SelectCanSearch = ({
    children,
    onChange,
    name,
    label,
    placeholder,
    value,
    isRequire,
    allowClear,
    className,
    disabled,
    onSearch,
    autoClearSearchValue,
    maxTagCount,
    mode,
    onBlur,
    onClear,
    onDropdownVisibleChange
}) => {
    const handleSearch = (value, key) => {
        onChange && onChange(value, key)
    }
    const handleSearchChange = (value, key) => {
        onSearch && onSearch(value, key)
    }
    const handleBlur = (value, key) => {
        onBlur && onBlur(value, key)
    }
    const handleClear = (value, key) => {
        onClear && onClear(value, key)
    }
    return (
        <SearchItem label={label} isRequire={isRequire} className={className}>
            <Select
                mode={mode}
                maxTagCount={maxTagCount}
                onSearch={(value) => handleSearchChange(value, name)}
                showSearch
                allowClear={allowClear}
                optionFilterProp="children"
                placeholder={placeholder}
                value={value}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => handleSearch(value, name)}
                onBlur={(value) => handleBlur(value, name)}
                onClear={(value) => handleClear(value, name)}
                disabled={disabled}
                autoClearSearchValue={autoClearSearchValue}
                onDropdownVisibleChange={onDropdownVisibleChange}
            >
                {children}
            </Select>
        </SearchItem>
    )
}

SelectCanSearch.Option = Option

SelectCanSearch.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array]),
    isRequire: PropTypes.bool,
    allowClear: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    onChangeInput: PropTypes.func,
    autoClearSearchValue: PropTypes.bool,
    maxTagCount: PropTypes.number,
    mode: PropTypes.string,
    filterOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    onBlur: PropTypes.func,
    onSearch: PropTypes.func,
    onClear: PropTypes.func,
    onDropdownVisibleChange: PropTypes.func
}
SelectCanSearch.defaultProps = {
    allowClear: true
}

export const SelectInputCanSearch = ({
    onChange,
    name,
    placeholder,
    allowClear,
    label,
    isRequire,
    className,
    options,
    value
}) => {
    const handleSearch = (value, key) => {
        onChange && onChange(value, key)
    }
    return (
        <SearchItem label={label} isRequire={isRequire} className={className}>
            <AutoComplete
                value={value}
                options={options}
                onChange={(e) => handleSearch(e, name)}
                placeholder={placeholder}
                filterOption={(input, option) => option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                allowClear={allowClear}
            ></AutoComplete>
        </SearchItem>
    )
}
SelectInputCanSearch.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    allowClear: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    isRequire: PropTypes.bool,
    className: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array])
}

/**
 *
 * 基础Select组件不支持搜索
 */
export const SearchSelectItem = ({
    children,
    onChange,
    name,
    label,
    placeholder,
    value,
    disabled,
    isRequire,
    className,
    defaultValue,
    allowClear,
    id,
    dropdownMatchSelectWidth,
    defaultOpen,
    onPopupScroll,
    mode,
    maxTagTextLength,
    maxTagCount
}) => {
    const handleSearch = (value, key) => {
        onChange && onChange(value, key, id)
    }
    return (
        <SearchItem label={label} isRequire={isRequire} className={className}>
            <Select
                showSearch
                allowClear={allowClear}
                dropdownMatchSelectWidth={dropdownMatchSelectWidth}
                placeholder={placeholder}
                value={value}
                onChange={(value) => handleSearch(value, name)}
                disabled={disabled}
                defaultValue={defaultValue}
                defaultOpen={defaultOpen}
                onPopupScroll={onPopupScroll}
                mode={mode}
                maxTagTextLength={maxTagTextLength}
                maxTagCount={maxTagCount}
            >
                {children}
            </Select>
        </SearchItem>
    )
}
SearchSelectItem.Option = Option

SearchSelectItem.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array]),
    disabled: PropTypes.bool,
    isRequire: PropTypes.bool,
    className: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    allowClear: PropTypes.bool,
    dropdownMatchSelectWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    id: PropTypes.string,
    defaultOpen: PropTypes.bool,
    onPopupScroll: PropTypes.func,
    mode: PropTypes.string,
    maxTagTextLength: PropTypes.number,
    maxTagCount: PropTypes.number
}
SearchSelectItem.defaultProps = {
    allowClear: true
}

/**
 *
 * Radio组件
 *
 */
export const RadioItem = ({ label, name, onChange, value, children, disabled, options, id }) => {
    const handleChange = (value, key) => {
        onChange && onChange(value, key, id)
    }
    return (
        <SearchItem label={label}>
            <Radio.Group
                onChange={(value) => handleChange(value, name)}
                value={value}
                disabled={disabled}
                options={options}
            >
                {children}
            </Radio.Group>
        </SearchItem>
    )
}
RadioItem.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    disabled: PropTypes.bool,
    options: PropTypes.array,
    id: PropTypes.string
}
RadioItem.Button = Radio.Button
RadioItem.Radio = Radio
/**
 *
 *多选框
 *
 */

export const CheckboxItem = ({ label, name, onChange, options, defaultValue, dCheck, value }) => {
    const handleChange = (value, key) => {
        onChange && onChange(value, key)
    }
    return (
        <SearchItem label={label}>
            <Checkbox.Group
                value={value}
                onChange={(value) => handleChange(value, name)}
                defaultValue={defaultValue || dCheck}
                options={options}
            />
        </SearchItem>
    )
}
CheckboxItem.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array,
    defaultValue: PropTypes.array,
    dCheck: PropTypes.array,
    value: PropTypes.array
}

/**
 * 基础搜索按钮
 */

export const SearchButton = ({
    handleClick,
    children,
    onClick,
    disabled,
    className,
    btnType = '',
    type = 'primary',
    loading,
    danger,
    icon
}) => {
    return (
        <SearchItem className={className}>
            <Button
                type={btnType || type}
                onClick={onClick || handleClick}
                disabled={disabled}
                loading={loading}
                danger={danger}
                icon={icon}
            >
                {children}
            </Button>
        </SearchItem>
    )
}
SearchButton.propTypes = {
    handleClick: PropTypes.func,
    onClick: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    btnType: PropTypes.string,
    type: PropTypes.string,
    loading: PropTypes.bool,
    danger: PropTypes.bool,
    icon: PropTypes.node
}

/**
 * 基础时间范围插件选择
 */
const { RangePicker } = DatePicker
export const SearchRangePicker = ({
    label,
    onChange,
    name,
    defaultValue,
    picker,
    showTime,
    disabledDate,
    placeholder = ['开始日期', '结束日期'],
    value,
    allowClear,
    ranges,
    onOpenChange,
    className
}) => {
    const handleTime = (value, key) => {
        if (value) {
            const time = {
                startTime: value[0]?.valueOf(),
                endTime: value[1]?.valueOf()
            }
            onChange && onChange(time, key)
        } else {
            const time = {
                startTime: null,
                endTime: null
            }
            onChange && onChange(time, key)
        }
    }
    const dealDisabledDate = (current) => {
        return (current && current < moment('2016', 'YYYY')) || (disabledDate && disabledDate(current))
    }
    const handleOpenChange = (open) => {
        onOpenChange?.(open)
    }
    return (
        <SearchItem label={label} className={className}>
            <RangePicker
                value={value}
                placeholder={placeholder}
                onChange={(value) => handleTime(value, name)}
                showTime={showTime}
                defaultValue={defaultValue}
                picker={picker}
                disabledDate={dealDisabledDate}
                allowClear={allowClear}
                ranges={ranges}
                onOpenChange={handleOpenChange}
            />
        </SearchItem>
    )
}
SearchRangePicker.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string,
    defaultValue: PropTypes.array,
    picker: PropTypes.string,
    showTime: PropTypes.bool,
    disabledDate: PropTypes.func,
    placeholder: PropTypes.array,
    value: PropTypes.array,
    allowClear: PropTypes.bool,
    ranges: PropTypes.object,
    onOpenChange: PropTypes.func,
    className: PropTypes.string
}

/**
 * 日期选择 年月日周天
 */
export const SearchDatePicker = ({
    picker,
    label,
    onChange,
    name,
    disabledDate,
    disabled,
    defaultValue,
    showTime,
    value,
    format,
    noDisabledData,
    allowClear,
    placeholder,
    isRequire
}) => {
    const handleDateChange = (value, name) => {
        onChange?.(value, name)
    }
    const dealDisabledDate = (current) => {
        return (
            (current && current < moment('2016', 'YYYY')) ||
            current > moment().add(1, 'year') ||
            (disabledDate && disabledDate(current))
        )
    }
    return (
        <SearchItem label={label} isRequire={isRequire}>
            <DatePicker
                allowClear={allowClear}
                format={format}
                disabledDate={noDisabledData ? '' : dealDisabledDate}
                onChange={(value) => handleDateChange(value, name)}
                picker={picker}
                disabled={disabled}
                defaultValue={defaultValue}
                showTime={showTime}
                placeholder={placeholder}
                value={typeof value === 'number' ? moment(value) : value}
            />
        </SearchItem>
    )
}
SearchDatePicker.defaultProps = {
    allowClear: true
}
SearchDatePicker.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    picker: PropTypes.string,
    name: PropTypes.string,
    disabledDate: PropTypes.func,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    showTime: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.number]),
    format: PropTypes.string,
    noDisabledData: PropTypes.bool,
    allowClear: PropTypes.bool,
    placeholder: PropTypes.string,
    isRequire: PropTypes.bool
}
